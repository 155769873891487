import React, { FunctionComponent, useEffect, useState } from "react";
import { AuthContext } from "web-common/src/services/AuthContextService";
import CognitoService from "web-common/src/services/CognitoService";
import { globalHistory, Location } from "@reach/router";
import { QueryParamProvider } from "use-query-params";
import ClickTracker from "web-common/src/components/Analytics/ClickTracker";
import ScrollTracker from "web-common/src/components/Analytics/ScrollTracker";

const App: FunctionComponent = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [tokenExpiryTimestamp, setTokenExpiryTimestamp] = useState(0);
  const [token, setToken] = useState("");
  useEffect(() => {
    CognitoService.getActiveSession()
      .then(session => {
        if (session.isValid()) {
          setAuthenticated(true);
          setToken(session.getIdToken().getJwtToken());
          setTokenExpiryTimestamp(session.getIdToken().getExpiration());
        }
      })
      .catch(() => setAuthenticated(false));
  }, [authenticated, token, tokenExpiryTimestamp]);

  return (
    <Location>
      {({ location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          <AuthContext.Provider
            value={{
              authenticated,
              setAuthenticated,
              tokenExpiryTimestamp,
              setTokenExpiryTimestamp,
              token,
              setToken
            }}
          >
            <ScrollTracker></ScrollTracker>
            <ClickTracker>{children}</ClickTracker>
          </AuthContext.Provider>
        </QueryParamProvider>
      )}
    </Location>
  );
};

export default App;
