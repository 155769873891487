import "web-common/src/styles/main.scss";
import Amplify from "@aws-amplify/auth";
import React from "react";
import { WrapRootElementBrowserArgs } from "gatsby";
import App from "web-common/src/components/App";

interface PluginOpts {
  cognitoRegion: string;
  cognitoUserPoolId: string;
  cognitoPoolWebClientId: string;
}

export const onClientEntry = (_: unknown, pluginOpts: PluginOpts) => {
  Amplify.configure({
    Auth: {
      region: pluginOpts.cognitoRegion,
      userPoolId: pluginOpts.cognitoUserPoolId,
      userPoolWebClientId: pluginOpts.cognitoPoolWebClientId
    }
  });
};

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  return <App>{element}</App>;
};
